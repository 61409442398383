.Join {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 67px;
  background: #f9f9f9;
  .join-inner {
    width: 92%;
    max-width: 692px;
    margin-left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    .qingyou-logo {
      width: 91px;
      height: auto;
      margin: 53px;
    }
    p {
      width: 100%;
      height: auto;
      text-align: center;
      color: #000000;
      margin-bottom: 20px;
    }
    .join-title {
      font-weight: 556;
      font-size: 30px;
      line-height: 36px;
    }
    .join-description {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
    }
    .join-link {
      color: #47ae73;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
  }
}
