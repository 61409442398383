@font-face {
    font-family: "YouSheBiaoTiHei";
    src: url("YouSheBiaoTiHei.eot"); /* IE9 */
    src: url("YouSheBiaoTiHei.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    
    url("YouSheBiaoTiHei.woff") format("woff"), /* chrome、firefox */
    url("YouSheBiaoTiHei.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
    
    url("YouSheBiaoTiHei.svg#YouSheBiaoTiHei") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}


