.part1-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
  }
  img {
    object-fit: fill;
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    z-index: 1;
  }
  .slg {
    font-size: 0;
    background: url(https://game.gtimg.cn/images/game/web202112/img/p1-slg.fd94e03.png)
      no-repeat 50%/100%;
    width: 1273px;
    height: 552px;
    margin: 160px auto;
    z-index: 1;
  }
  .banner-wrap {
    margin: 0 auto 50px;
    padding: 0 80px;
    width: 100%;
    height: 27px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 999;
    .banner-item {
      width: 120px;
      height: 25px;
      font-family: PingFang-SC-Regular;
      font-size: 26px;
      letter-spacing: 4px;
      color: #ffffff;
      line-height: 25px;
      cursor: pointer;
    }
  }
  .divide {
    margin-bottom: 70px;
    width: 100vw;
    height: 3px;
    background-color: #ffffff;
    opacity: 0.3;
    z-index: 999;
  }
}
