@font-face {
    font-family: "HYYakuHei-55W";
    src: url("HYYakuHei-55W.eot"); /* IE9 */
    src: url("HYYakuHei-55W.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    
    url("HYYakuHei-55W.woff") format("woff"), /* chrome、firefox */
    url("HYYakuHei-55W.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
    
    url("HYYakuHei-55W.svg#HYYakuHei-55W") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}


