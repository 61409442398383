.part3-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .content-wrap {
    position: relative;
    .qxy-wrap {
      position: absolute;
      right: -5%;
      bottom: -10%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 279px;
      height: 1040px;
      z-index: 999;
      .qxy {
        width: 279px;
        height: 449px;
        background-image: url("../../../../assets/index_part3/qxy.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin-top: 45.5%;
      }
    }
    .content {
      display: flex;
      align-items: center;
      width: 1945px;
      height: 812px;
      background-color: #fbfbfb;
      border-radius: 40px;
      margin: 107px 0 0 44px;
      overflow: hidden;
      .carousel-wrap {
        width: 1245px;
        height: 812px;
        overflow: hidden;
        .img-wrap {
          // border-top-left-radius: 40px;
          // border-bottom-left-radius: 40px;
          // overflow: hidden;
        }
        .img {
          height: 812px;
          // border-radius: 40px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
        .ant-carousel .slick-dots {
          li {
            cursor: pointer;
            width: 50px;
            height: 6px;
            background-color: #2d7921;
            opacity: 0.4;
            button {
              cursor: pointer;
              width: 50px;
              height: 6px;
              background-color: #2d7921;
              opacity: 0.4;
            }
          }
          .slick-active {
            width: 119px;
            height: 6px;
            background-color: #2d7921;
            opacity: 1;
            button {
              width: 119px;
              height: 6px;
              background-color: #2d7921;
              opacity: 1;
            }
          }
        }
      }
      .right-wrap {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        // align-items: center;
        .title {
          width: 207px;
          height: 47px;
          font-family: PingFangSC-Medium;
          font-size: 48px;
          letter-spacing: 2px;
          color: #4d4950;
          line-height: 47px;
          margin: 103px 0 0 56px;
        }
        .slg {
          width: 450px;
          height: 45px;
          font-family: PingFangSC-Light;
          font-size: 48px;
          letter-spacing: 2px;
          color: #4d4950;
          line-height: 45px;
          margin: 28px 0 0 56px;
        }
        .slg-content {
          width: 564px;
          height: 189px;
          font-family: PingFangSC-Thin;
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 2px;
          color: #4d4950;
          margin: 38px 0 0 56px;
        }
        .people {
          display: flex;
          margin: 73px 0 0 56px;
          .all {
            width: 200px;
            height: 116px;
            background-image: url("../../../../assets/index_part3/all.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .graduate {
            width: 166px;
            height: 116px;
            background-image: url("../../../../assets/index_part3/graduate.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-left: 61px;
          }
        }
        .nav-wrap {
          display: flex;
          width: 430px;
          align-items: center;
          justify-content: space-around;
          margin: 62px 0 0 56px;
          border: 3px solid #94d175;
          border-radius: 30px;
          padding: 5px 20px;
          .nav-content {
            display: flex;
            align-items: center;
            height: 40px;
            font-family: PingFang-SC-Regular;
            font-size: 30px;
            line-height: 40px;
            text-align: center;
            letter-spacing: 6px;
            color: #94d175;
            cursor: pointer;
            .nav-icon {
              width: 36px;
              height: 36px;
              background-image: url("../../../../assets/right-green.png");
              background-position: center, center;
              background-size: 100%, 100%;
              background-repeat: no-repeat;
            }
          }
        }
        .nav-wrap:hover {
          background-color: #94d175;
          .nav-content {
            color: white;
            .nav-icon {
              background-image: url("../../../../assets/right.png");
            }
          }
        }
      }
    }
  }
}
