.title-wrap {
  display: flex;
  align-items: center;
  .title-icon {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 8px solid #47ae73;
    margin-right: 23px;
  }
  .title-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 891px;
    height: 122px;
    text-align: justify;
    text-align-last: justify;
    .title-bg {
      width: 891px;
      height: 60px;
      background-image: url("../../../../assets/PRONDLY\ PRESENT\ BY\ QINGYOU.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .title {
      position: absolute;
      top: 31px;
      width: 873px;
      height: 56px;
      font-family: HYYakuHei-55W;
      font-size: 63px;
      line-height: 56px;
      // letter-spacing: 16px;
      margin: 0 auto;
      color: #4d4950;
      text-align: justify;
    }
    .title-detail {
      position: absolute;
      top: 106px;
      width: 865px;
      height: 25px;
      font-family: PingFang-SC-Regular;
      font-size: 25px;
      line-height: 25px;
      // letter-spacing: 18px;
      color: #4d4950;
      text-align: justify;
    }
  }
}
@media screen and (max-width: 920px) {
  .title-wrap {
    .title-icon {
      width: 3vw;
      height: 3vw;
      border-radius: 50%;
      border: 2px solid #47ae73;
      margin-right: 1vw;
      transform: translateY(-60%);
    }
    .title-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      width: 78vw;
      height: 13vw;
      text-align: justify;
      text-align-last: justify;
      .title-bg {
        width: 78vw;
        height: 5vw;
        background-image: url("../../../../assets/PRONDLY\ PRESENT\ BY\ QINGYOU.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .title {
        position: absolute;
        top: 2vw;
        width: 78vw;
        height: 5vw;
        font-family: HYYakuHei-55W;
        font-size: 5vw;
        line-height: 5vw;
        margin: 0 auto;
        color: #4d4950;
        text-align: justify;
      }
      .title-detail {
        position: absolute;
        top: 7vw;
        width: 78vw;
        height: 6vw;
        font-family: PingFang-SC-Regular;
        font-size: 2vw;
        line-height: 6vw;
        color: #4d4950;
        text-align: justify;
      }
    }
  }
}
