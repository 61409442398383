.CoverMobile {
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-color: rgba(169, 220, 91, 1);
  .cover-bg {
    width: 100%;
    height: 600px;
    overflow: hidden;
    position: absolute;
    .bg-inner {
      width: 100%;
      height: 100vh;
      background: linear-gradient(
          138.89deg,
          rgba(228, 255, 212, 0.2) 5.4%,
          rgba(65, 129, 64, 0) 39.42%
        ),
        linear-gradient(
          116.39deg,
          rgba(255, 255, 255, 0.1) 9.12%,
          rgba(113, 113, 113, 0.072) 50.42%
        ),
        linear-gradient(
          226.28deg,
          rgba(126, 147, 119, 0.5) -7.92%,
          rgba(90, 228, 42, 0) 47.54%,
          rgba(90, 228, 42, 0) 68.34%
        ),
        radial-gradient(
            53.52% 76.97% at 17.6% 102.54%,
            rgba(239, 255, 54, 0.8) 0%,
            rgba(255, 255, 255, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(
            86.13% 86.13% at 92.5% 13.87%,
            rgba(91, 253, 194, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(
            39.03% 77.18% at 89.03% 82.08%,
            #d3ff23 0%,
            rgba(212, 255, 39, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        #a9dc5b;
      position: absolute;
      filter: blur(10px);
      .back-circle {
        width: 1000px;
        height: 1000px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg) scale(1.8);
        top: 50%;
        animation: spin 20s linear infinite;
        filter: blur(70px);
        :nth-child(1) {
          background-color: rgba(117, 180, 255, 0.5);
          transform: translate(250px, 62.5px);
        }
        :nth-child(2) {
          background-color: rgba(91, 253, 194, 0.5);
          transform: translate(34px, 437.5px);
        }
        :nth-child(3) {
          background-color: rgba(255, 215, 75, 0.5);
          transform: translate(466px, 437.5px);
        }
        .circle-item {
          width: 500px;
          height: 500px;
          border-radius: 500px;
          position: absolute;
          flex: 1;
        }
      }
    }
  }
  .cover-content {
    width: 100%;
    height: 600px;
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    color: white;
    text-align: center;
    white-space: nowrap;
    padding-bottom: 50px;
    box-sizing: border-box;
    .people-title {
      width: 460px;
      font-family: "HYYakuHei";
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 54px;
      letter-spacing: 0.28em;
      color: #ffffff;
      transform: matrix(0.97, 0, -0.27, 1, 0, 0);
      text-align: left;
      position: absolute;
      p {
        width: 100%;
        text-align: left;
        margin: 0px;
      }
      :nth-child(1) {
      }
      :last-child {
        text-align: right;
      }
    }
    .people-subtitle {
      position: absolute;
      width: 400px;
      font-family: "Galano Grotesque Alt DEMO";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
      color: rgba(255, 255, 255, 0.4);
      transform: matrix(0.97, 0, -0.27, 1, 0, 0);
      p {
        width: 100%;
        text-align: left;
        margin: 0px;
        margin-left: 120px;
      }
      :last-child {
        margin-bottom: 45px;
      }
    }
    .slogan-en {
      width: 728px;
      height: 135px;
      line-height: 135px;
      font-size: 100px;
      font-weight: 700;
      transform: matrix(0.97, 0, -0.27, 1, 0, 0);
      margin-top: 45px;
      font-family: "Galano Grotesque Alt DEMO";
      text-align: justify;
      text-align-last: justify;
    }
    .slogan-zh {
      width: 728px;
      height: 81px;
      line-height: 81px;
      font-size: 60px;
      font-weight: 800;
      transform: matrix(0.97, 0, -0.27, 1, 0, 0);
      font-family: "HYYakuHei";
      text-align: justify;
      text-align-last: justify;
      margin-bottom: 160px;
    }
    .next-arrow {
      width: 70px;
      height: 70px;
      background-image: url("../../../../assets/people/Arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 56px;
      animation: arrowRun 4s ease infinite;
    }
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg) scale(1.8);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg) scale(1.8);
  }
}

@keyframes arrowRun {
  from {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  to {
    transform: translateY(0px);
  }
}
