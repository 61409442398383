@font-face {
    font-family: "HYYakuHei-85W";
    src: url("HYYakuHei-85W.eot"); /* IE9 */
    src: url("HYYakuHei-85W.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    
    url("HYYakuHei-85W.woff") format("woff"), /* chrome、firefox */
    url("HYYakuHei-85W.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
    
    url("HYYakuHei-85W.svg#HYYakuHei-85W") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}


