@font-face {
    font-family: "PingFang-SC-Regular";
    src: url("PingFang-SC-Regular.eot"); /* IE9 */
    src: url("PingFang-SC-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    
    url("PingFang-SC-Regular.woff") format("woff"), /* chrome、firefox */
    url("PingFang-SC-Regular.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
    
    url("PingFang-SC-Regular.svg#PingFang-SC-Regular") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}


