.mobile-part4-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .mobile-part4-container {
    @media screen and (max-width: 375px) {
      height: 136vw;
    }
    @media screen and (min-width: 410px) {
      height: 130vw;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86vw;
    height: 146vw;

    .carousel-wrap {
      width: 86vw;
      height: 46vw;
      margin-top: 4vw;
      border-top-left-radius: 4vw;
      border-top-right-radius: 4vw;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../../../../assets/index_part4/people.png);
    }
    .bottom-wrap {
      width: 86vw;
      height: 70vw;
      background-color: #fbfbfb;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      border-bottom-left-radius: 4vw;
      border-bottom-right-radius: 4vw;
      @media screen and (min-width: 410px) {
        height: 54vw;
      }
      .bottom-content {
        width: 82vw;
        height: 100%;
        margin-top: 4vw;
        @media screen and (min-width: 410px) {
          margin-top: 2vw;
        }
        .story-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .passage-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 26vw;
            height: 27vw;
            background-color: #ffffff;
            border-radius: 4vw;
            @media screen and (min-width: 410px) {
              width: 22vw;
              height: 23vw;
            }
            .passage-bg {
              width: 22vw;
              height: 17vw;
              @media screen and (min-width: 410px) {
                width: 20vw;
                height: 12vw;
              }
              border-top-left-radius: 4vw;
              border-top-right-radius: 4vw;
              overflow: hidden;
              margin-top: 2vw;
            }
            .passage-title {
              width: 20vw;
              height: 4vw;
              font-family: PingFangSC-Regular;
              font-size: 10px;
              color: #4d4950;
            }
          }
        }
        .some-number {
          width: 46vw;
          margin-left: 32vw;
          .establish-time {
            margin-top: 6vw;
            width: 40vw;
            height: 11vw;
            background-image: url(../../../../assets/index_part4/esTime.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            @media screen and (min-width: 410px) {
              margin-top: 2vw;
            }
          }
          .product {
            margin-top: 6vw;
            display: flex;
            width: 46vw;
            height: 11vw;
            @media screen and (max-width: 375px) {
              margin-top: 2vw;
            }
            @media screen and (min-width: 410px) {
              margin-top: 2vw;
            }
            .all {
              width: 20vw;
              height: 11vw;
              background-image: url(../../../../assets/index_part4/15w.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
            .active {
              margin-left: 6vw;
              width: 20vw;
              height: 11vw;
              background-image: url(../../../../assets/index_part4/6w.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    .nav-wrap {
      height: 6vw;
      background-color: #a5d88a;
      border-radius: 3vw;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 1.5px solid #a5d88a;
      padding: 2.5px 10px;
      margin: 6vw 0 0 32vw;
      .nav-content {
        display: flex;
        align-items: center;
        height: 4vw;
        font-family: PingFangSC-Medium;
        font-size: 4vw;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1vw;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        .nav-icon {
          width: 4vw;
          height: 4vw;
          background-image: url("../../../../assets/right.png");
          background-position: center, center;
          background-size: 100%, 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .qxy-wrap {
      position: absolute;
      bottom: 0;
      left: 1vw;
      width: 30vw;
      height: 39vw;
      background-image: url("../../../../assets/index_part4/qxy.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: 999;
    }
  }
}
