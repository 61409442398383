.mobile-part5-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .mobile-part5-container {
    @media screen and (max-width: 375px) {
      height: 136vw;
    }
    @media screen and (min-width: 410px) {
      height: 130vw;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86vw;
    height: 146vw;
    .swiper-wrap {
      margin-top: 4vw;
      width: 100vw;
      height: 126vw;
      align-self: center;
      @media screen and (max-width: 375px) {
        height: 116vw;
      }
      @media screen and (min-width: 410px) {
        height: 110vw;
      }
    }
    .list-item {
      display: flex;
      flex-direction: column;
      width: 53vw;
      height: 84vw;
      box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
      border-radius: 4vw;
      margin: 15vw 8vw;
      transition: all 0.3s;
      opacity: 0.6;
      @media screen and (max-width: 375px) {
        height: 74vw;
        margin: 12vw 8vw;
      }
      @media screen and (min-width: 410px) {
        height: 70vw;
        margin: 10vw 8vw;
      }
      .img-wrap {
        width: 53vw;
        height: 37.2vw;
        img {
          border-top-left-radius: 4vw;
          border-top-right-radius: 4vw;
          width: 53vw;
          height: 37.2vw;
          object-fit: cover;
        }
      }

      .item-content {
        width: 100%;
        flex: 1;
        padding: 15px;
        background-color: rgb(255, 255, 255);
        border-bottom-left-radius: 4vw;
        border-bottom-right-radius: 4vw;
        .item-title {
          font-family: YouSheBiaoTiHei;
          font-size: 5.6vw;
          line-height: 4.75vw;
          color: #2d7921;
          margin: 3vw 0;
        }
        .item-msg {
          height: 30vw;
          font-size: 3vw;
          color: #313131;
          @media screen and (max-width: 375px) {
            height: 0;
          }
          @media screen and (min-width: 410px) {
            height: 0;
          }
        }
      }
    }

    .list-item-active {
      transform: scale(1.26);
      opacity: 1;
    }
    .join-wrap {
      width: 66.7vw;
      margin: 0 auto;
      .join {
        width: 27vw;
        height: 7vw;
        line-height: 7vw;
        text-align: center;
        color: #fff;
        font-size: 4vw;
        cursor: pointer;
        border-radius: 50px;
        background: linear-gradient(90deg, #8bd21e, #efe70e);
      }
    }
    .xcx-wrap {
      position: absolute;
      bottom: 0vw;
      right: -3vw;
      width: 36vw;
      height: 43vw;
      background-image: url("../../../../assets/index_part5/xcx.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: 999;
      transform: translateZ(1000px);
    }
  }
}
