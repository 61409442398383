.part2-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .content-wrap {
    position: relative;
    width: 1958px;
    height: 1145px;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    .xcx-wrap {
      position: absolute;
      left: -6%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 292px;
      height: 1139px;
      z-index: 999;
      .xcx {
        width: 292px;
        height: 472px;
        background-image: url("../../../../assets/index_part2/xcx.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    .mobile-prototype {
      display: flex;
      margin-top: 86px;
      height: 857px;
      // flex-shrink: 0;
      // width: 1958px;
      // height: 857px;
      // overflow: hidden;
      // background-image: url("../../../../assets/index_part2/prototype.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      .mobile-item {
        width: 337px;
        height: 687px;
      }
      .mobile {
        width: 390px;
        height: 750px;
      }
      .mobile1 {
        margin: 78px 0 0 56px;
      }
      .mobile2 {
        margin-left: 38px;
      }
      .mobile3 {
        margin: 78px 0 0 38px;
      }
      .mobile4 {
        margin-left: 38px;
      }
      .mobile5 {
        margin: 78px 0 0 38px;
      }
    }
  }
  .nav-wrap {
    display: flex;
    width: 502px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 3px solid #94d175;
    border-radius: 30px;
    padding: 5px 20px;
    .nav-content {
      display: flex;
      align-items: center;
      height: 40px;
      font-family: PingFang-SC-Regular;
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 6px;
      color: #94d175;
      cursor: pointer;
      z-index: 100;
      .nav-icon {
        width: 36px;
        height: 36px;
        background-image: url("../../../../assets/right-green.png");
        background-position: center, center;
        background-size: 100%, 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .nav-wrap:hover {
    background-color: #94d175;
    .nav-content {
      color: white;
      .nav-icon {
        background-image: url("../../../../assets/right.png");
      }
    }
  }
}
