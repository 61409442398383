.swiper-wrap {
  // position: relative;
  overflow: hidden;
  .swiper-content {
    width: 100vw;
    height: 100vh;
    transition: all 300ms ease-in-out;
  }
  .indicator-wrap {
    position: absolute;
    top: 50%;
    right: 5vw;
    width: 30px;
    // height: 240px;
    transform: translateY(-50%);
    z-index: 10;
    .indicator-item {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-clip: content-box;
      background-color: #fff;
      padding: 6px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 3px solid transparent;
      opacity: 1;
      margin: 15px 0;
      transition: all 300ms ease-in-out;
      cursor: pointer;
    }
    .selected-item {
      border: 2px solid #fff;
    }
    .product-indicator-item {
      width: 4vh;
      height: 4vh;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin: 24px 0;
    }
    .product-selected-item {
      box-shadow: 0 9px 18px 0 rgb(0 0 0 / 15%);
      -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
  @keyframes next-animation {
    0% {
      bottom: 0px;
    }
    10% {
      bottom: 1px;
    }
    20% {
      bottom: 2px;
    }
    30% {
      bottom: 3px;
    }
    40% {
      bottom: 4px;
    }
    50% {
      bottom: 5px;
    }
    60% {
      bottom: 4px;
    }
    70% {
      bottom: 3px;
    }
    80% {
      bottom: 2px;
    }
    90% {
      bottom: 1px;
    }
    100% {
      bottom: 0px;
    }
  }
  .next {
    width: 10.5px;
    height: 17px;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAACXBIWXMAAAsTAAALEwEAmpwYAAABfUlEQVRIie2WPWsCQRCGH48QIjZXCCkujdhLmgipbewCKYRUaWxt/R/+AFtLsbG47v6BkEDaECEWEtAuGAtTzLuJn3eHZ5e8sHA7O/Pc7N7u3OY4rAC4B66BK+ATmALPQB94j4ndAfWAhdpI/YGenb0n31jVgZkyaQH+Hh9fY1P51uNgCyA8ANoHDhWzAw30tjAFaFuhYjem38WmkCazbfmK7TpDEUu7dQTMqSVGEaCpzjHZOfliND2gCrwA8wzAuRhVD1vMSQaY0wQIPGB5ApjT0gPegPIJYGWxaAAroJQBVhKjAVDANmYnA7AjRsEZ2thnrxwBqyi2vW68wCrJmBQVZE2BYkZi7B2cALUUsJp8Y5MIgAhb4CFwx+YJKso2lE8UB1vXIzaNldoMWyfXH8lnR7kEcAm4AS7VHwNPwGuarP7113WrdhJF/G7oKCvM1cq62k/NO6SzBGAe+MIuSOd6zmfJ0MeOm7sgjUn43SadZbAK84Bd5wbAR5zzN1GYYGPxMEZrAAAAAElFTkSuQmCC)
      no-repeat 50%/100%;
    margin: 0 auto;
    -webkit-animation: next-animation 1.5s infinite;
    animation: next-animation 1.5s infinite;
    z-index: 10;
  }
  .hide {
    display: none;
  }
  .reset {
    width: 72px;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    color: black;
  }
}
