@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -15%, 0);
    transform: translate3d(0, -15%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -15%, 0);
    -ms-transform: translate3d(0, -15%, 0);
    transform: translate3d(0, -15%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fade-in-down {
  animation-duration: 0.9s;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-timing-function: linear;
}
// @keyframes down {
//   0% {
//     -webkit-transform: translate3d(0, -15%, 0);
//     -ms-transform: translate3d(0, -15%, 0);
//     transform: translate3d(0, -15%, 0);
//   }
//   100% {
//     -webkit-transform: none;
//     -ms-transform: none;
//     transform: none;
//   }
// }
// .down{
//   animation-duration: 0.8s;
//   -webkit-animation-name: down;
//   animation-name: down;
//   animation-timing-function: ease-in-out;
// }
.down {
  transition: all 0.7s;
}
