.mobile-part2-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .mobile-part2-container {
    @media screen and (max-width: 375px) {
      height: 136vw;
    }
    @media screen and (min-width: 410px) {
      height: 130vw;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86vw;
    height: 146vw;
    .nav-wrap {
      position: absolute;
      bottom: 0;
      height: 6vw;
      background-color: #a5d88a;
      border-radius: 3vw;
      display: flex;
      align-items: center;
      justify-content: space-around;
      align-self: center;
      border: 1.5px solid #a5d88a;
      padding: 2.5px 10px;
      margin-top: 7vw;
      .nav-content {
        display: flex;
        align-items: center;
        height: 4vw;
        font-family: PingFangSC-Medium;
        font-size: 4vw;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1vw;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        .nav-icon {
          width: 4vw;
          height: 4vw;
          background-image: url("../../../../assets/right.png");
          background-position: center, center;
          background-size: 100%, 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .mobile-wrap {
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 100vw;
      margin-top: 10vw;
      height: 109vw;
      overflow: initial !important;
      @media screen and (min-width: 410px) {
        height: 100vw;
      }
      .mobile-content {
        // width: 295%;
        height: 109vw;
        background-image: url("../../../../assets/index_part2/mobile-pro.png");
        background-size: 100% 100%;
        background-position: center;
        @media screen and (min-width: 410px) {
          height: 100vw;
        }
      }
      .scrollbar-track{
        display: none !important;
      }
      .njupt {
        width: 48vw;
        height: 95vw;
        background-image: url(../../../../assets/index_part2/njupt.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .graduate {
        width: 19vw;
        height: 95vw;
        background-image: url(../../../../assets/index_part2/dGraduate.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 14vw;
      }
      .freegate {
        width: 19vw;
        height: 95vw;
        background-image: url(../../../../assets/index_part2/dFreegate.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 14vw;
      }
    }
    .xcx-wrap {
      position: absolute;
      // bottom: -15vw;
      bottom: 0;
      left: -3vw;
      width: 26vw;
      height: 42vw;
      background-image: url("../../../../assets/index_part2/xcx.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: 999;
      transform: translateZ(1000px);
    }
  }
}
