.MemberSelector {
  width: 100%;
  height: 50px;
  text-align: center;
  margin: 50px 0px;
  list-style: none;
  padding: 0px;
  mask-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0) 99%
  );
  white-space: nowrap;
  overflow-x: scroll;
  li {
    width: 98px;
    height: 48px;
    display: inline-block;
    list-style: none;
    color: black;
    font-family: "Source Han Sans";
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    line-height: 48px;
    border-radius: 10px;
    margin: 0px 10px;
    user-select: none;
    cursor: pointer;
    transform: scale(1);
    ::marker {
      display: none;
    }
    :hover {
      transform: scale(1.3);
    }
  }
  .selected {
    background: #47ae73;
    color: white;
  }
  :first-child {
    margin-left: 12%;
  }
  :last-child {
    margin-right: 12%;
  }
}

.MemberSelector::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

// @media (max-width: 1400px) {

// }
