@import "antd/dist/antd.min.css";
@import "./assets/fonts/HYYakuHei-85W/HYYakuHei-85W.css";
@import "./assets/fonts/HYYakuHei/HYYakuHei-55W.css";
@import "./assets/fonts/PingFang-SC-Regular/PingFang-SC-Regular.css";
@import "./assets/fonts/YouSheBiaoTiHei/YouSheBiaoTiHei.css";
* {
  margin: 0;
}
*,
:after,
:before {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

