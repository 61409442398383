.part4-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .content-wrap {
    position: relative;
    .qxy-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      left: -7%;
      bottom: -5%;
      width: 343px;
      height: 1041px;
      z-index: 999;
      .qxy {
        width: 343px;
        height: 443px;
        background-image: url("../../../../assets/index_part4/qxy.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    .content {
      display: flex;
      align-items: center;
      width: 1945px;
      height: 812px;
      background-color: #fbfbfb;
      border-radius: 40px;
      margin: 107px 0 0 44px;
      .img-wrap {
        width: 1221px;
        height: 100%;
        background-image: url("../../../../assets/index_part4/people.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
      }
      .right-wrap {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        // align-items: center;
        .passage-wrap {
          display: flex;
          flex-direction: column;
          // justify-content: space-evenly;
          height: 624px;
          margin: 0 0 0 60px;
          .passage {
            display: flex;
            align-items: center;
            width: 597px;
            margin-top: 51px;
            .bg-wrap {
              width: 252px;
              height: 142px;
            }
            .passage-content {
              width: 350px;
              height: 118px;
              margin-left: 35px;
              .passage-title {
                font-size: 24px;
                font-weight: 600;
                font-family: PingFangSC-Thin;
              }
              .passage-detail {
                line-height: 28px;
                font-size: 24px;
                font-family: PingFangSC-Thin;
              }
            }
          }
        }
        .nav-wrap {
          display: flex;
          width: 380px;
          align-items: center;
          justify-content: space-around;
          margin: 50px 0 0 60px;
          border: 3px solid #94d175;
          border-radius: 30px;
          padding: 5px 20px;
          .nav-content {
            display: flex;
            align-items: center;
            height: 40px;
            font-family: PingFang-SC-Regular;
            font-size: 30px;
            line-height: 40px;
            text-align: center;
            letter-spacing: 6px;
            color: #94d175;
            cursor: pointer;
            .nav-icon {
              width: 36px;
              height: 36px;
              background-image: url("../../../../assets/right-green.png");
              background-position: center, center;
              background-size: 100%, 100%;
              background-repeat: no-repeat;
            }
          }
        }
        .nav-wrap:hover {
          background-color: #94d175;
          .nav-content {
            color: white;
            .nav-icon {
              background-image: url("../../../../assets/right.png");
            }
          }
        }
      }
    }
  }
}
