section.part {
  color: #676767;
  font-size: 16px;
  overflow: hidden;
  line-height: 30px;

  p {
    margin-bottom: 0;
  }

  &:nth-of-type(1) {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;

    perspective: 1000px;
    perspective-origin: 75% 75%;
    transform-style: preserve-3d;
    background: url(../../assets/about/bg.7b8aa6bf.png) 50% / cover no-repeat;
    @media screen and (max-width: 1250px) {
      height: 800px;
    }
    @media screen and (max-width: 920px) {
      height: 708px;
    }

    .contanier {
      top: 192px;
      text-align: center;
      position: absolute;
      @media screen and (max-width: 1250px) {
        top: 224px;
      }
      @media screen and (max-width: 920px) {
        top: 176px;
        text-align: left;
      }

      .title {
        width: 550px;
        height: 90px;
        margin-bottom: 40px;
        background: url(../../assets/about/t1.06eec6d5.png) 100%/100% auto
        no-repeat;
        @media screen and (max-width: 920px) {
          width: 260px;
          height: 100px;
          background: url(../../assets/about/t11.80835a6a.png) 100% / auto 100%
          no-repeat;
        }
      }

      .content-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 920px) {
          width: 319px;
          height: 222px;
          font-size: 13px;
          line-height: 22px;
        }

        .content {
          margin-bottom: 15px;

          &:last-of-type {
            font-weight: 600;
            margin-bottom: 100px;
          }
        }
      }

      .more {
        color: #6ba32a;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
      }
    }

    .autoimg {
      position: absolute;
      bottom: 16px;
      width: 820px;
      height: 610px;
      transform-origin: bottom;
      animation: flip-x 1.3s linear 1;
      background: url(../../assets/about/top_bg.63f6fb23.png) 100%/100% 100%
      no-repeat;
      @media screen and (max-width: 1250px) {
        width: 650px;
        height: 500px;
      }
      @media screen and (max-width: 920px) {
        width: 525px;
        height: 375px;
        bottom: 0;
      }

      @keyframes flip-x {
        0% {
          transform: rotateX(-45deg) translateX(40vw);
        }
        50% {
          transform: rotateX(10deg) translateX(40vw);
        }
        70% {
          transform: rotateX(-6deg) translateX(40vw);
        }
        85% {
          transform: rotateX(4deg) translateX(40vw);
        }
        95% {
          transform: rotateX(-2deg) translateX(40vw);
        }
        100% {
          transform: rotateX(0deg) translateX(40vw);
        }
      }
    }
  }

  &:nth-of-type(2) {
    width: 100vw;
    height: 795px;
    background: url(../../assets/about/landing-bg.0c73a22a.png) 50%/100% 100%
    no-repeat;
    @media screen and (max-width: 920px) {
      height: 1104px;
    }

    .contanier {
      top: 83px;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        width: 443px;
        margin-bottom: 60px;
        @media screen and (max-width: 920px) {
          width: 288px;
        }
      }

      .content-wrap {
        margin-bottom: 32px;
        @media screen and (max-width: 920px) {
          width: 320px;
          line-height: 24px;
          font-family: PingFang-SC-Regular;
        }

        .content {
          margin: 0;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 28.8px;
          font-family: Helvetica;
        }
      }

      .more {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        cursor: pointer;
        color: #6ba32a;
        font-family: PingFangSC-Semibold;
      }

      .product {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 25px;
        transform: translateY(4.8px);
        @media screen and (max-width: 920px) {
          width: 368px;
          flex-wrap: wrap;
        }

        .product-item {
          width: 224px;
          height: 448px;
          margin-right: -48px;
          @media screen and (max-width: 920px) {
            width: 176px;
            height: 352px;
            margin-right: 0;
          }

          &:nth-child(2n) {
            @media screen and (max-width: 920px) {
              margin-left: 8px;
            }
          }

          &:last-of-type {
            margin-right: 0;
            @media screen and (max-width: 920px) {
              display: none;
            }
          }
        }
      }
    }
  }

  &:nth-of-type(3) {
    width: 100vw;
    height: 799px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../assets/about/bg3left.74b818e2.png) 0/535px 524px
    no-repeat,
    url(../../assets/about/bg3right.5050e76d.png) 100% 275px/541px 524px
    no-repeat,
    #363;
    @media screen and (max-width: 920px) {
      height: 1140px;
      background-size: 270px, 361px, 305px, 361px;
      background-position: 0 0, 100% 580px;
    }

    .contanier {
      width: 1250px;
      height: 571px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1280px) {
        justify-content: space-around;
      }
      @media screen and (max-width: 920px) {
        width: 80vw;
        height: 1040px;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
      }

      .left-content {
        width: 382px;
        @media screen and (max-width: 1160px) {
          width: 280px;
        }
        @media screen and (max-width: 920px) {
          width: 311px;
        }

        .title {
          width: 274px;
          margin-bottom: 35px;
        }

        .content-wrap {
          color: #d1d1d1;
          font-weight: 300;
          line-height: 28.8px;
          margin-bottom: 60px;
          @media screen and (max-width: 920px) {
            margin-bottom: 26px;
          }

          .content {
            margin-bottom: 21px;
          }
        }

        .persons {
          display: flex;
          margin-bottom: 39px;
          @media screen and (max-width: 920px) {
            margin-bottom: 31px;
          }
          .person-num {
            width: 75px;
            font-size: 46px;
            font-weight: normal;
            line-height: 40px;
            color: #92d241;
            @media screen and (max-width: 920px) {
              width: 60px;
              font-size: 36px;
            }
          }

          .person-tag {
            @media screen and (max-width: 920px) {
              line-height: 30px;
            }
          }

          & > div:first-of-type {
            margin-right: 49px;
          }
        }

        .join {
          line-height: 49px;
          text-align: center;
          width: 140px;
          height: 49px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
          border-radius: 24px;
          background: linear-gradient(90deg, #8bd21e, #efe70e);
          @media screen and (max-width: 920px) {
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .right-people {
        width: 699px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        @media screen and (max-width: 1160px) {
          width: 460px;
        }

        .people-wall {
          width: 100%;
          height: 520px;
          display: grid;
          overflow: hidden;
          margin-bottom: 22px;
          grid-gap: 9px 8px;
          grid-template-rows: 124px [main-start] repeat(2, 124px) [main-end] 124px;
          grid-template-columns: 110px [main-start] repeat(2, 110px) [main-end] repeat(3, 110px);
          @media screen and (max-width: 1160px) {
            height: 660px;
            grid-template-rows: 125px [main-start] repeat(2, 125px) [main-end] 125px;
            grid-template-columns: [main-start] repeat(2, 110px) [main-end] repeat(2, 110px);
          }

          @media screen and (max-width: 920px) {
            width: 343px;
            height: 386px;
            grid-gap: 6px;
            margin: 0 auto 16px;
            grid-template-rows: 92px [main-start] repeat(2, 92px) [main-end] 92px;
            grid-template-columns: [main-start] repeat(2, 81px) [main-end] repeat(2, 81px);
          }

          .person {
            opacity: 1;
            width: 100%;
            height: auto;
            background-color: #f5f5f5;
            animation: fade-in__noopacity 1s ease-out;
            @keyframes fade-in__noopacity {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            &:nth-child(8) {
              grid-row-end: main-end;
              grid-row-start: main-start;
              grid-column-end: main-end;
              grid-column-start: main-start;
            }

            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(11),
            &:nth-child(12),
            &:nth-child(13),
            &:nth-child(17),
            &:nth-child(21) {
              opacity: 0.6;
              animation: fade-in__opacity 0.6s ease-out;

              @keyframes fade-in__opacity {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 0.6;
                }
              }
            }
          }
        }

        .people-sort {
          color: #92d241;
          font-size: 18px;
          cursor: pointer;
          @media screen and (max-width: 920px) {
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }
  }

  &:nth-of-type(4) {
    width: 100vw;
    height: 786px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: url(../../assets/about/bg4.2c60aa1d.png) 100%/100% 100%
    no-repeat;
    @media screen and (max-width: 1180px) {
      height: 1052px;
    }
    @media screen and (max-width: 920px) {
      height: 944px;
    }

    &>img {
      z-index: 1;
    }

    .mascot {
      width: 288px;
      margin-top: 230px;
      position: absolute;
      @media screen and (max-width: 920px) {
        display: none;
      }
    }

    .contanier {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      top: 119px;
      @media screen and (max-width: 1250px) {
        top: 183px;
      }

      @media screen and (max-width: 920px) {
        top: 89px;
      }

      .title {
        width: 320px;
        margin-bottom: 32px;
        @media screen and (max-width: 920px) {
          width: 288px;
        }
      }

      .content-wrap {
        display: flex;
        margin-bottom: 21px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 920px) {
          margin-bottom: 36.8px;
        }

        .content {
          width: 832px;
          margin-bottom: 0;
          text-align: center;
          line-height: 28.8px;
          font-family: PingFang-SC-Regular;
          @media screen and (max-width: 920px) {
            width: 304px;
            line-height: 25.6px;
          }
        }
      }

      .more {
        cursor: pointer;
        color: #6ba32a;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 64px;
        font-stretch: normal;
        font-family: PingFangSC-Semibold;
        @media screen and (max-width: 920px) {
          margin-bottom: 16px;
        }
      }

      .card-wrap {
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1180px) {
          flex-direction: column;
        }

        .card {
          width: 30vw;
          height: 208px;
          margin: 0 9.6px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          @media screen and (max-width: 1180px) {
            width: 83vw;
            height: 144px;
            margin: 4px 0;
          }
          @media screen and (max-width: 920px) {
            width: 90vw;
          }

          .img {
            width: 192px;
            padding-left: 17.6px;
          }

          .sub {
            width: 80px;
            height: 32px;
            font-weight: 700;
            margin-top: 16px;
            letter-spacing: 0;
            font-stretch: normal;
            transform: translateX(19.2px);
            color: hsla(0, 0%, 100%, 0.5);
            font-family: PingFangSC-Semibold;
          }

          &:nth-child(1) {
            background: rgb(126, 199, 255)
            url(../../assets/about/blue.b4287f9f.png) 100% / auto 100%
            no-repeat;
          }

          &:nth-child(2) {
            background: rgb(255, 172, 176)
            url(../../assets/about/pink.a591ec5b.png) 100% / auto 100%
            no-repeat;
          }

          &:nth-child(3) {
            background: rgb(255, 190, 84)
            url(../../assets/about/orange.339bcafc.png) 100% / auto 100%
            no-repeat;
          }
        }
      }

      .divider {
        height: 1px;
        width: 1200px;
        margin: 80px 0;
        background-color: #c0c4cc;
        @media screen and (max-width: 1250px) {
          width: 90%;
          margin-top: 12px;
          margin-bottom: 16px;
        }
      }

      .source {
        width: 83vw;
        height: 352px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: -webkit-linear-gradient(0deg, #8bd21e, #d8d23e);
        @media screen and (max-width: 1100px) {
          width: 83vw;
        }
        @media screen and (max-width: 920px) {
          width: 90vw;
        }
        @media screen and (max-width: 720px) {
          height: 100%;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 30px 0;
        }

        .left {
          height: 224px;
          margin-left: 48px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media screen and (max-width: 920px) {
            width: 80%;
            margin-left: 27.2px;
            align-items: flex-start;
          }
          @media screen and (max-width: 720px) {
            height: 144px;
          }

          .img {
            height: 88px;
            @media screen and (max-width: 920px) {
              height: 52.8px;
            }
          }

          .text {
            font-weight: 400;
            font-size: 25.6px;
            letter-spacing: 0;
            font-stretch: normal;
            font-family: PingFangSC-Semibold;
            @media screen and (max-width: 920px) {
              font-size: 16px;
            }

            & > p:first-child {
              width: 288px;
              line-height: 40px;
              color: hsla(0, 0%, 100%, 0.7);
              @media screen and (max-width: 920px) {
                width: auto;
              }
            }

            & > p:last-child {
              width: 320px;
              color: #fff;
              line-height: 40px;
              @media screen and (max-width: 920px) {
                width: auto;
              }
            }
          }
        }

        .right {
          width: 464px;
          height: 288px;
          cursor: pointer;
          margin-right: 48px;
          transition: all 0.2s ease-in-out;
          &:hover {
            transform: scale(1.1);
            @media screen and(max-width: 920px) {
              transform: scale(1);
            }
          }
          @media screen and (max-width: 1100px) {
            width: 50%;
            height: auto;
          }
          @media screen and (max-width: 920px) {
            width: 55%;
            height: auto;
          }
          @media screen and (max-width: 720px) {
            width: 80%;
            height: auto;
            margin-right: 0;
            max-width: 380px;
            margin-top: 32px;
            margin-left: 27.2px;
          }
        }
      }
    }
  }
}
