.person-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .person-num {
    width: 75px;
    font-family: HYYakuHei-85W;
    font-size: 46px;
    font-weight: normal;
    line-height: 40px;
    color: #92d241;
  }

  .person-tag {
    font-family: PingFang-SC-Regular;
    font-size: 15px;
    color: #6ba32a;
    line-height: 35px;
  }

  .person-ren {
    font-family: HYYakuHei-85W;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 4px;
    color: #92d241;
  }

}

