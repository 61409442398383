.MemberCard {
  max-width: 304px;
  width: 65%;
  height: auto;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  transition: all ease 0.5s;
  overflow: hidden;
  margin-bottom: 30px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
  .card-cover {
    width: 100%;
    max-height: 204px;
    overflow: hidden;
    margin: 0;
    img {
      width: 100%;
      height: auto;
      transform: scale(1) translateY(-30px);
      transition: all ease 0.5s;
      user-select: none;
      -webkit-user-drag: none;
    }
  }
  .card-content {
    padding: 27px;
    .card-name {
      font-weight: 600;
      font-size: 25px;
      line-height: 35px;
      color: black;
      margin-bottom: 7px;
    }
    .card-description {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #4a4a4a;
    }
  }
}

.MemberCard:hover {
  background: #ffffff;
  box-shadow: 0px 38px 96px rgba(0, 0, 0, 0.1);
  .card-cover {
    img {
      // width: 100%;
      // height: 204px;
      transform: scale(1.2) translateY(-30px);
    }
  }
}
