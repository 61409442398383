.part5-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .content-wrap {
    position: relative;
    .xcx-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      right: -7%;
      bottom: -5%;
      width: 347px;
      height: 1037px;
      z-index: 999;
      .xcx {
        width: 347px;
        height: 418px;
        background-image: url("../../../../assets/index_part5/xcx.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin-top: 45.5%;
      }
    }
    .work-list {
      width: 1977px;
      height: 677px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 101px;
      .list-item:hover {
        box-shadow: 0px 38px 96px rgba(0, 0, 0, 0.1);
        img {
          transform: scale(1.062);
        }
        .item-content-active{
          .item-indicator{
            opacity: 1;
          }
        }
      }
      .list-item {
        display: flex;
        flex-direction: column;
        width: 429px;
        height: 677px;
        margin-right: 40px;
        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
        border-radius: 40px;
        overflow: hidden;
        transition: all 0.5s;
        cursor: pointer;
        .img-wrap {
          width: 100%;
          height: 298px;
          overflow: hidden;
          img {
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            width: 100%;
            height: 298px;
            object-fit: cover;
            transition: all 0.5s;
          }
        }

        .item-content-active {
          position: relative;
          width: 100%;
          flex: 1;
          padding: 30px;
          background-color: rgb(255, 255, 255);
          border-bottom-left-radius: 40px;
          border-bottom-right-radius: 40px;
          background-size: 10px 361px;
          background-repeat: no-repeat;
          transition: all 0.5s;
          .item-indicator {
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 361px;
            border-bottom-left-radius: 40px;
            background-image: linear-gradient(
              0deg,
              #a6db5b 0%,
              #33bb33 48%,
              #3ea12e 100%
            );
            opacity: 0;
            transition: all 0.5s;
          }
          .item-title {
            font-family: YouSheBiaoTiHei;
            font-size: 50px;
            line-height: 64px;
            color: #2d7921;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 21px 0;
          }
          .item-msg {
            // font-family: PingFangSC-Semibold;
            font-size: 26px;
            line-height: 42px;
            color: #313131;
          }
        }
      }
    }
    .nav-wrap {
      display: flex;
      width: 300px;
      align-items: center;
      justify-content: space-around;
      margin: 102px 0 0 0;
      border: 3px solid #94d175;
      border-radius: 30px;
      padding: 5px 20px;
      .nav-content {
        display: flex;
        align-items: center;
        height: 40px;
        font-family: PingFang-SC-Regular;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 6px;
        color: #94d175;
        cursor: pointer;
        .nav-icon {
          width: 36px;
          height: 36px;
          background-image: url("../../../../assets/right-green.png");
          background-position: center, center;
          background-size: 100%, 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .nav-wrap:hover {
      background-color: #94d175;
      .nav-content {
        color: white;
        .nav-icon {
          background-image: url("../../../../assets/right.png");
        }
      }
    }
    .join {
      width: 275px;
      line-height: 56px;
      text-align: center;
      height: 56px;
      color: #fff;
      font-size: 28px;
      cursor: pointer;
      border-radius: 50px;
      background: linear-gradient(90deg, #8bd21e, #efe70e);
      margin-top: 100px;
    }
  }
}
