.GrowCard {
  width: 100%;
  height: 350px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 90px;
  transition: all 0.5s;
  .pic-part {
    width: 691px;
    height: 350px;
    line-height: 350px;
    text-align: center;
    overflow: hidden;
    img {
      width: 691px;
      transform: scale(1);
      transition: transform 0.5s;
      -webkit-user-drag: none;
    }
  }
  .content-part {
    width: calc(100% - 691px);
    height: 350px;
    padding: 50px 80px;
    font-size: 18px;
    .card-title {
      line-height: 35px;
      font-weight: 600;
      font-size: 25px;
      margin-bottom: 16px;
    }
    .card-content {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
    }
    .card-data-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
      .card-space {
        flex: auto;
      }
      .card-data {
        color: rgba(71, 174, 115, 0.8);
        // width: 110px;
        display: inline-block;
        .data-text {
          font-family: "HYYakuHei";
          text-align: center;
          height: 70px;
          :first-child {
            display: inline;
            font-weight: 800;
            font-size: 50px;
            line-height: 153%;
          }
          :last-child {
            display: inline;
            font-weight: 800;
            font-size: 30px;
            line-height: 153%;
          }
        }
        .data-label {
          font-weight: 400;
          font-size: 18px;
          line-height: 153%;
          text-align: center;
        }
      }
    }
  }
}

.GrowCard:hover {
  background: #ffffff;
  box-shadow: 0px 38px 96px rgba(0, 0, 0, 0.1);
  .pic-part {
    img {
      transform: scale(1.062);
    }
  }
}

@media (max-width: 1200px) {
  .GrowCard {
    height: auto;
    .pic-part {
      width: 100%;
      height: auto;
      line-height: 0px;
      img {
        width: 100%;
      }
    }
    .content-part {
      width: 100%;
      padding: 30px 40px;
      height: auto;
    }
  }
}
