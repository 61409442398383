.Members {
  width: 100%;
  box-sizing: border-box;
  padding-top: 57px;
  padding-bottom: 100px;
  .members-inner {
    max-width: 1280px;
    width: 92%;
    margin-left: 50%;
    transform: translateX(-50%);
    .members-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

@media (max-width: 1080px) {
  .Members {
    .members-inner {
      width: 100%;
      .Title:first-child {
        width: 92%;
        margin-left: 4%;
      }
      .members-container {
        display: block;
        white-space: nowrap;
        overflow-x: scroll;
        padding-bottom: 80px;
        .MemberCard:first-child {
          margin-left: calc(4% + 8px);
        }
        .MemberCard:last-child {
          margin-right: calc(4% + 8px);
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-width: 1080px) {
  .Members {
    .members-inner {
      .members-container {
        .MemberCard {
          width: calc(25% - 16px);
        }
      }
    }
  }
}

.members-container::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
