@media screen and (min-width: 901px) {
  .product-part-mock-img{
    height: 100vh;
  }
  .product-icon-mobile {
    display: none;
  }
  .product-part-title{
    font-size: 2.4rem;
  }
}
@media  screen and (max-height: 667px) and (max-width: 375px) {
  .product-part-mock{
    display: none !important;
  }
}
@media screen and (max-width: 1225px) {
  .product-part-mock{
    position: fixed;
    right: 100px;
    z-index: 0;
  }
  .product-part-body{
    justify-content: start !important;
  }
}
@media screen and (max-width: 435px) {
  .product-part-body{
    max-width: 100vw !important;
  }
  .product-part-wrap{
    justify-content: unset !important;
  }
  .product-part-msg{
    margin-left: 0px !important;
    margin-right: 0px;
    justify-content: center;
    width: 100vw !important;
    padding: 3rem;
    margin-top: 0px !important;
  }
  .product-part-mock{
    width: 100vw;
    .product-part-mock-img {
      width: 100vw;
      object-fit: cover;
    }
  }
  .product-part-title{
    font-size: 1.4rem !important;
  }
}
@media screen and (max-width: 900px) {
  .product-part-qrcode{
    display: none;
  }
  .product-part-mock{
    position: unset;
    height: auto !important;
    width: 100%;
    display: flex;
    justify-content: center;
    .product-part-mock-img{
      height: auto !important;
      object-fit: contain;
      width: 100%;
    }
  }
  .product-part-msg{
    margin-top: 40px;
    margin-left: 50px;
    justify-content: unset;
    align-items: unset;
    margin-left: 50px;
  }
  .product-part-detail{
    height: auto;
  }
  .product-part-icon{
    visibility: hidden;
  }
  .product-icon-mobile{
    height: 3rem;
    width: 3rem;
    margin-right: 24px;
  }
  .product-part-title {
    margin-bottom: 24px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 720px) {
  .product-part-body{
    position: relative;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .product-part-icon{
    display: none;
  }
  .product-part-msg{
    margin-left: 50px;
  }
  .product-part-mock{
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    .product-part-mock-img {
      object-fit: contain;
    }
  }
  .product-part-msg{
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
  }
}

.product-part-wrap {
  width: 100vw;
  height: 100%;
  // background-image: url("https://p9-bd-official.byteimg.com/img/bytedance-cn/dd665e447cd09c339875f9580a5478bd~noop.image");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  .product-part-body {
    width: 100vw;
    max-width: 78vw;
    margin-left: 100px;
    margin-right: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .product-part-msg {
    display: flex;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    width: 340px;
    .product-part-icon {
      width: 72px;
      height: 72px;
      // background-image: url("https://p6-bd-official.byteimg.com/img/bytedance-cn/4ac74bbefc4455d0b350fff1fcd530c7~noop.image");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .product-part-title {
      width: 100%;
      margin-top: 32px;
      height: 32px;
      font-size: 1.7rem;
      line-height: 32px;
      font-family: "Gilroy-Bold", -apple-system, "Sofia Pro", "Microsoft YaHei",
        "Helvetica", "Arial", sans-serif;
      font-weight: 700;
    }
    .product-part-detail {
      font-family: "Gilroy-Bold", -apple-system, "Sofia Pro", "Microsoft YaHei",
        "Helvetica", "Arial", sans-serif;
      width: 100%;
      margin-top: 24px;
      font-size: 16px;
      white-space: pre-wrap;
      line-height: 2;
    }
  }
  .product-part-qrcode{
    margin-top: 2rem;
  }
  .product-part-mock {
    height: 100vh;
  }
}
