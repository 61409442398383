.Title {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  margin-bottom: 50px;
  .title-icon {
    width: 55px;
    height: 55px;
    margin-right: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .title {
    height: 32px;
    line-height: 32px;
    font-family: "HYYakuHei";
    font-weight: 700;
    font-size: 32px;
  }
  .subtitle {
    height: 28px;
    line-height: 28px;
    font-weight: 400;
    font-size: 20px;
  }
}
