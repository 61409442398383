.mobile-part1-wrap {
  width: 100%;
  height: 100%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    user-select: none;
  }
}
