.mobile-part3-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .mobile-part3-container {
    @media screen and (max-width: 375px) {
      height: 136vw;
    }
    @media screen and (min-width: 410px) {
      height: 130vw;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86vw;
    height: 146vw;
    .carousel-wrap {
      width: 86vw;
      height: 46vw;
      margin-top: 4vw;
      border-top-left-radius: 4vw;
      border-top-right-radius: 4vw;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../../../../assets/index_part3/group_photo1.jpeg);
    }
    .bottom-wrap {
      width: 86vw;
      height: 70vw;
      background-color: #fbfbfb;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      border-bottom-left-radius: 4vw;
      border-bottom-right-radius: 4vw;
      @media screen and (min-width: 410px) {
        height: 54vw;
      }
      .bottom-content {
        width: 79vw;
        height: 100%;
        margin-top: 4vw;
        @media screen and (min-width: 410px) {
          margin-top: 2vw;
        }
        .title {
          height: 5vw;
          font-family: PingFangSC-Medium;
          font-size: 5vw;
          line-height: 5vw;
          color: #4d4950;
        }
        .slg {
          height: 5vw;
          font-family: PingFangSC-Light;
          font-size: 5vw;
          line-height: 5vw;
          color: #4d4950;
          margin-top: 3vw;
          @media screen and (min-width: 410px) {
            margin-top: 2vw;
          }
        }
        .slg-content {
          height: 17vw;
          font-family: PingFangSC-Thin;
          font-size: 3vw;
          line-height: 5vw;
          color: #4d4950;
          margin-top: 3vw;
          @media screen and (min-width: 410px) {
            margin-top: 2vw;
          }
        }
        .people {
          display: flex;
          align-items: center;
          margin-top: 7vw;
          @media screen and (max-width: 375px) {
            margin-top: 4vw;
          }
          @media screen and (min-width: 410px) {
            margin-top: 4vw;
          }
          .all {
            width: 100px;
            height: 58px;
            background-image: url("../../../../assets/index_part3/all.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .graduate {
            width: 83px;
            height: 58px;
            background-image: url("../../../../assets/index_part3/graduate.png");
            background-repeat: no-repeat;
            background-size: cover;
            margin-left: 6vw;
          }
        }
      }
    }
    .nav-wrap {
      height: 6vw;
      background-color: #a5d88a;
      border-radius: 3vw;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 1.5px solid #a5d88a;
      padding: 2.5px 10px;
      margin-top: 7vw;
      .nav-content {
        display: flex;
        align-items: center;
        height: 4vw;
        font-family: PingFangSC-Medium;
        font-size: 4vw;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1vw;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        .nav-icon {
          width: 4vw;
          height: 4vw;
          background-image: url("../../../../assets/right.png");
          background-position: center, center;
          background-size: 100%, 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .qxy-wrap {
      @media screen and (max-width: 375px) {
        bottom: -5vw;
      }
      position: absolute;
      bottom: 0;
      right: 1vw;
      width: 25vw;
      height: 40vw;
      background-image: url("../../../../assets/index_part3/qxy.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: 10;
    }
  }
}
