.Grow {
  width: 100%;
  box-sizing: border-box;
  padding-top: 167px;
  background-image: url("../../../../assets/people/grow-bg.svg");
  background-size: 2947px auto;
  background-repeat: no-repeat;
  background-position-x: calc(50vw - 1362px);
  background-position-y: -1210px;
  padding-bottom: 120px;
  .grow-inner {
    max-width: 1280px;
    width: 92%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
